class LoadMore extends HTMLElement {
  constructor() {
    super();
    this._button = this.querySelector('button');
    this._nextPage = 2;
    this._currentPage = 1;
    this._isLoading = false;
    this._productGrid = document.querySelector('#product-grid');
    this._perPage = this._productGrid.getAttribute('per-page');
    this._collectionTemplate = document.querySelector('main');
    this._productCount = this.querySelector('#product-count');
    this._savedPosition = localStorage.getItem('scrollPosition');
    this._pageCount = parseInt(localStorage.getItem('pageCount'));
    this._savedCollection = localStorage.getItem('savedCollection');
    this._collectionTemplate.classList.add('transition-opacity', 'opacity-0');

    console.log(this._perPage);
  }

  connectedCallback() {
    this.bindEvent();
    this._productCount.innerHTML = this._productGrid.querySelectorAll('.grid__item').length;

    window.addEventListener('visibilitychange', function () {
      console.log('beforeunload');
      var page = document.querySelector('#product-grid').childElementCount;
      localStorage.setItem('scrollPosition', window.scrollY);
      localStorage.setItem('pageCount', Math.ceil(page / this._perPage));
      localStorage.setItem('savedCollection', window.location.pathname);
    });

    console.log(this._savedCollection, this._pageCount, this._savedPosition);

    if (this._savedPosition && this._pageCount && this._savedCollection == window.location.pathname) {
      // Load products via API call
      // Example: loadProducts(productsCount); // Define this function to load products
      this.multiFetch();
    } else {
      this._collectionTemplate.classList.remove('opacity-0');
    }
  }

  addParamsToUrl(url, params) {
    // Create a URL object
    let urlObj = new URL(url, window.location.origin);

    // Get the current search params of the URL
    let searchParams = urlObj.searchParams;

    // Iterate over the params object and add each key-value pair to the searchParams
    for (let key in params) {
      searchParams.set(key, params[key]);
    }

    // The updated URL string will be in the href property
    return urlObj.href;
  }

  bindEvent() {
    this._button.addEventListener('click', () => {
      this.expandShowMore();
    });
  }

  async multiFetch() {
    if (this._currentPage >= this._pageCount) {
      setTimeout(() => {
        if (this._savedPosition > 0) {
          window.scrollTo(0, parseInt(this._savedPosition));
        }
        this._collectionTemplate.classList.remove('opacity-0');
      }, 100); // Adjust timeout as needed
      //show product grid
      return;
    }
    this.expandShowMore(this._currentPage + 1);
  }

  async expandShowMore(page) {
    if (this._isLoading) return;
    this._isLoading = true;

    const searchParams = new URLSearchParams(window.location.search);
    const url = this.getAttribute('collection-handle') + '?' + searchParams;

    //grab current filters & params
    let paramsObj = {};

    if (page) {
      paramsObj['page'] = page;
    } else {
      paramsObj['page'] = this._nextPage;
    }

    const newURL = this.addParamsToUrl(url, paramsObj);

    fetch(newURL)
      .then((response) => response.text())
      .then((responseText) => {
        const html = new DOMParser().parseFromString(responseText, 'text/html');
        const collectionCount = html.querySelector('#collection-count').getAttribute('count');
        const products = html.querySelector('#product-grid').children;

        Array.from(products).forEach((product) => {
          this._productGrid.appendChild(product);
        });

        if (this._productGrid.childElementCount >= parseInt(collectionCount)) {
          this._button.classList.add('!hidden');
        }

        this._nextPage++;
        this._currentPage++;
        this._isLoading = false;
        this._productCount.innerHTML = this._productGrid.childElementCount;
        if (page) {
          this.multiFetch();
        }

        console.log('sywm trigger');
        document.dispatchEvent(new CustomEvent('swym:collections-loaded'));
      })
      .catch((e) => {
        console.error(e);
      });
  }
}

customElements.define('load-more', LoadMore);
